@import '_variables';

.loading-type {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -(10em / 1000);

  .mobile.landscape & {
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -(20em / 1000);
  }

  @include small() {
    font-size: 42px;
    line-height: 55px;

    .mobile.landscape & {
      font-size: 42px;
      line-height: 55px;
    }
  }

  @include tablet() {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: -(20em / 1000);
  }
}

.welcome-type {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -(20em / 1000);

  &.long-title {
    font-size: 51px;
    line-height: 54px;
  }

  @include small() {
    font-size: 50px;
    line-height: 55px;
  }

  @include tablet() {
    font-size: 90px;
    line-height: 95px;
  }
}

.enter-button-type {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: (30em / 1000);
  text-transform: uppercase;

  .mobile.landscape & {
    font-size: 70px;
  }

  @include small() {
    font-size: 50px;
    line-height: 60px;

    .mobile.landscape & {
      font-size: 50px;
      line-height: 60px;
    }
  }

  @include tablet() {
    font-size: 90px;
    line-height: 100px;
    letter-spacing: (30em / 1000);
  }
}

.title-seq-type {
  font-size: 63px;
  line-height: 65px;
  letter-spacing: -(20em / 1000);

  .mobile.landscape & {
    font-size: 77px;
    line-height: 76px;
    letter-spacing: -(10em / 1000);
  }

  @include small() {
    font-size: 57px;
    line-height: 60px;
    .mobile.landscape & {
      font-size: 57px;
      line-height: 60px;
    }
  }

  @include tablet() {
    font-size: 108px;
    line-height: 112px;
    letter-spacing: -(15em / 1000);
  }
}

.bottom-box-button {
  font-family: 'Vizner';
  letter-spacing: (10em/ 1000);
  font-size: 25px;
  line-height: 40px;

  @include tablet() {
    font-size: 28px;
    line-height: 50px;
  }

  @include small() {
    font-size: 22px;
    line-height: 36px;
  }
}

.bottom-box-type {
  font-size: 22px;
  line-height: 27px;

  @include small() {
    font-size: 18px;
    line-height: 22px;
  }

  @include tablet() {
    font-size: 29px;
    line-height: 27px;
    letter-spacing: 0;
  }
}

.bottom-box-type.no-bg {
  @extend .bottom-box-type;
  line-height: 30px;

  .mobile.landscape & {
    font-size: 32px;
    line-height: 40px;
  }

  @include small() {
    line-height: 26px;
  }

  @include tablet() {
    line-height: 40px;
  }
}

.hint-type {
  font-size: 23px;

  .mobile.landscape & {
    font-size: 25px;
  }

  @include small() {
    font-size: 25px;
    line-height: 33px;
  }

  @include tablet() {
    font-size: 33px;
  }
}

.instruction-type {
  font-size: 23px;
  line-height: 32px;
  letter-spacing: (15em / 1000);

  .mobile.landscape & {
    font-size: 25px;
  }

  @include small() {
    font-size: 19px;
    line-height: 25px;

    .mobile.landscape & {
      font-size: 19px;
      line-height: 25px;
    }
  }

  @include tablet() {
    font-size: 30px;
    line-height: 34px;
  }
}

.confirmation-type {
  @extend .overlay-button;

  // @include small() {
  //   font-size: 25px;
  // }

  // @include tablet() {
  //   font-size: 31px;
  //   line-height: 40px;
  // };
}

.language-type {
  font-size: 15px;
  line-height: 1.3em;
  letter-spacing: (70em/1000);
  padding-bottom: 2px;
  text-transform: uppercase;

  @include small() {
    font-size: 12px;
    line-height: 1.3em;
  }

  @include tablet() {
    font-size: 20px;
  }

  // @include desktop() {
  //   font-size: 22px * 0.6;
  //   padding-bottom: 0;
  //   padding-top: 1px;
  // }

  .mobile.landscape & {
    font-size: 17px;
  }
}

// .about-type-small {
//   font-size: 22px * 0.7;
//   line-height: 27px * 0.9;
//   letter-spacing: -(5em / 1000);

//   @include desktop() {
//     font-size: 19px * 0.7;
//     line-height: 24px * 0.9;
//     letter-spacing: -(5em / 1000);
//   }
// }

.about-type-big {
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0;
  // transform: translate3d(0, 0, -1);

  @include small() {
    font-size: 16px;
    line-height: 22px;
  }

  @include tablet() {
    font-size: 25px;
    line-height: 34px;
    letter-spacing: (10em / 1000);
  }

  .mobile.landscape & {
    font-size: 23px;
    line-height: 32px;
    letter-spacing: (10em / 1000);
  }
}

// .credits-type-big {
//   font-size: 41px;
//   line-height: 50px;

//   @include small() {
//     font-size: 35px;
//     line-height: 42px;
//   }

//   @include tablet() {
//     font-size: 68px;
//     line-height: 82px;
//   }
// }

// .credits-type {
//   font-size: 26px;
//   line-height: 32px;

//   @include small() {
//     font-size: 20px;
//     line-height: 26px;
//   }

//   @include tablet() {
//     font-size: 40px;
//     line-height: 50px;
//   }
// }

.overlay-type {
  // same as instruction/confirmation
  font-size: 23px;
  line-height: 32px;
  letter-spacing: (10em / 1000);

  @include tablet() {
    font-size: 29px;
    line-height: 44px;
  }

  .mobile.landscape & {
    font-size: 25px;
    line-height: 34px;
  }

  @include small() {
    font-size: 20px;
    line-height: 26px;

    .mobile.landscape & {
      font-size: 20px;
      line-height: 26px;
    }
  }
}

.overlay-button {
  font-size: 32px;
  letter-spacing: (50em / 1000);
  text-transform: uppercase;
  line-height: 1.2;

  @include small() {
    font-size: 23px;
    line-height: 29px;
  }

  @include tablet() {
    font-size: 45px;
    // line-height: 45px;
  }
}

.debug-type {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 10px;
  line-height: 20px;
}

a,
a:hover,
a:visited,
a:link {
  color: $textColor;
  pointer-events: all !important;
  text-decoration: none;
  box-shadow: 0 0.04em 0 0.0001em $textColor;

  .safari & {
    box-shadow: 0 0.07em 0 0.0001em $textColor;
  }
}
