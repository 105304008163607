$textColor: #fff;
$pink: #ffe3f3;

// z-indices
$zCamera: 0;
$zViewer: 1;
$zUi: 10;
$zTextAnimations: 9;
$zAbout: 100;
$zOverlay: 9999;
$zDebug: 99999;

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/

@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0,
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $alpha: alpha($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue * $alpha)
      percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}

// Breakpoints
$phone-width: 350px;
$tablet-width: 750px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin small {
  @media (orientation: portrait) and (max-width: #{$phone-width - 1px}), (orientation: landscape) and (max-width: 568px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.evenly-like {
  display: flex;
  justify-content: space-between;

  &:before,
  &:after {
    content: '';
    display: block;
  }
}
