@import '../../_typography';
@import '../../_variables';

.Overlay {
  @extend .overlay-type;
  position: absolute;
  z-index: $zOverlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);

  .LabelButton {
    // @extend .overlay-button;
    margin-top: 2.2em;
  }

  .CloseButton {
    z-index: $zOverlay * 2;
  }

  .OverlayContent {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $textColor;
    padding: 0 24px;
    width: 100%;

    a {
      color: $textColor !important;
      box-shadow: 0 0.04em 0 0.0001em $textColor !important;

      .safari & {
        box-shadow: 0 0.07em 0 0.0001em $textColor !important;
      }
    }

    p + p {
      margin-top: 1em;
    }

    .mobile.landscape & {
      padding: 0 80px;

      .LabelButton {
        margin-top: 1.7em;
      }
    }

    .android.mobile.landscape & {
      padding: 0 38px;

      .LabelButton {
        margin-top: 0.75em;
      }
    }
  }

  @include small() {
    .OverlayContent {
      max-width: 460px;

      .mobile.landscape & {
        padding: 0 0px;

        .LabelButton {
          margin-top: 0.75em;
        }
      }
    }
  }

  @include tablet() {
    .LabelButton {
      margin-top: 2em;
    }

    .OverlayContent {
      max-width: 574px;
      margin: 0 auto;
      padding: 0;
      position: relative;
      // top: -20px;
    }
  }
}
