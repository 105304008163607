@import '../../_variables';
@import '../../_typography';

.Loading {
  position: absolute;
  z-index: $zOverlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .text {
    @extend .overlay-button;
    text-transform: capitalize;
    letter-spacing: (30em / 1000);
    padding-bottom: 28px;

    @include tablet() {
      padding-bottom: 58px;
    }
  }
}
