@import '../../_typography';
@import '../../_variables';

.BottomBar {
  width: 100%;
  // height: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  pointer-events: all !important;

  &.has-background {
    @include scrimGradient(rgba(0, 0, 0, 0.9), 'to top');

    .text {
      @extend .bottom-box-button;
      padding: 0;
      margin: 15px 12px 16px 12px;
      border-radius: 100px;
      background: white;
      color: black;
      height: 45px;
    }
  }

  &.has-gradient {
    background: rgba(0, 0, 0, 0.5);

    .text {
      padding: 0;
    }
  }

  &.no-bg {
    padding-bottom: 13px;

    .text {
      @extend .bottom-box-type.no-bg;
    }
  }

  &.has-icon {
    .text {
      text-align: left;
      padding-right: 0;
    }
  }

  .text {
    @extend .bottom-box-type;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 2px;
    flex: 1;
    text-align: center;
  }

  .ShareButton {
    padding-right: 25px;
  }

  .mobile.landscape & {
    &.has-background {
      .text {
        margin-left: auto;
        margin-right: auto;
        max-width: 360px;
      }
    }

    &.no-bg {
      padding-bottom: 20px;
    }
  }

  @include small() {
    // height: 68px;

    &.no-bg {
      padding-bottom: 20px;
    }

    &.has-background {
      .text {
        height: 38px;
        margin-bottom: 12px;
      }
    }
  }

  @include tablet() {
    &.no-bg {
      padding-bottom: 40px;
    }

    &.has-background {
      .text {
        height: 55px;
        border-radius: 120px;
        margin-left: auto;
        margin-right: auto;
        max-width: 420px;
        margin-bottom: 18px;
      }
    }
  }

  // @include tablet() {
  //   // height: 67px;

  //   .text {
  //     padding-top: 4px;

  //     br {
  //       content: ' ';
  //       &:after {
  //         content: ' ';
  //       }
  //     }
  //   }
  // }
}
