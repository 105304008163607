@import '../../_typography';
@import '../../_variables';

.Assignment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .Overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .Button {
    pointer-events: all !important;
  }

  .instruction,
  .confirmation-text {
    @extend .instruction-type;
    color: $textColor;
    position: absolute;
    left: 0;
    right: 0;
    padding: 14px 15px 50px 15px;
    @include scrimGradient(rgba(0, 0, 0, 0.2), 'to bottom');
    text-align: center;
    pointer-events: none;
  }

  .confirmation-buttons {
    pointer-events: all !important;
    position: absolute;
    right: 16px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-70px);
    padding: 0 0 0 50px;

    &:after {
      content: '•';
      font-size: 30px;
      line-height: 10px;
      position: absolute;
      left: 52px;
      right: 2px;
      transform: translateY(60px);
      // border: 1px solid $textColor;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -10px;
      left: -15px;
      right: -100px;
      bottom: -10px;
      background: rgba(0, 0, 0, 0.275);
      filter: blur(40px);
    }

    .mobile.landscape & {
      transform: translateY(-61px);
      margin-right: env(safe-area-inset-right);
    }
  }

  .confirmation-button {
    @extend .confirmation-type;
    background: none;
    border: 0;
    color: $textColor;
  }

  h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include small() {
    .instruction,
    .confirmation-text {
      padding-top: 11px;
    }

    .confirmation-buttons {
      height: 100px;
      &:after {
        font-size: 20px;
        transform: translateY(45px);
      }

      .mobile.landscape & {
        transform: translateY(-50px);
      }
    }
  }

  @include tablet() {
    .instruction,
    .confirmation-text {
      padding-top: 20px;
    }

    .confirmation-buttons {
      right: 36px;
      height: 180px;
      transform: translateY(-100px);

      &:after {
        transform: translateY(85px);
      }
    }
  }
}
