@import '../../_variables';

.Camera {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: $zCamera;
  top: 0;
}

.Camera video {
  width: 100px;
  height: auto;
}

.Camera canvas {
  width: 100px;
  height: auto;
}
