.Share {
  padding-bottom: 0.5em;

  .LabelButton {
    display: inline-block;
    text-transform: none;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;
  }
}
