@import '../../_variables';
@import '../../_typography';

.Button {
  background: none;
  border: 0;
  line-height: 0;

  circle,
  ellipse {
    // fill: $textColor;
    fill: $textColor;
  }

  svg {
    pointer-events: none;
  }
}

.NavButtons {
  .Button {
    z-index: $zUi + 1;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .AboutButton {
    position: absolute;
    padding: 8px;
    top: 5px;
    right: 3px;

    .landscape & {
      margin-right: env(safe-area-inset-right);
    }

    svg {
      border-radius: 50%;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    }
  }

  @include small() {
    .Button svg {
      width: 22px;
      height: auto;
    }
  }

  @include tablet() {
    .Button svg {
      width: 34px;
      height: auto;
    }
    .Button {
      right: 10px;
    }
    .AboutButton {
      top: 10px;
    }
  }
}

.CloseButton {
  position: absolute;
  z-index: $zOverlay + 1;
  padding: 8px;
  top: 5px;
  right: 3px;

  .landscape & {
    margin-right: env(safe-area-inset-right);
  }

  svg {
    width: 25px;
    height: auto;
  }

  path {
    fill: black;
  }

  circle {
    fill: $textColor;
  }

  @include small() {
    right: 3px;
    svg {
      width: 22px;
      heigh: auto;
    }
    // top: 0;
    // padding-top: 8px;
    // svg {
    //   width: 12px;
    //   height: auto;
    // }
  }

  @include tablet() {
    top: 10px;
    right: 10px;

    svg {
      width: 34px;
      height: auto;
    }
  }
}

.RecordButton {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background: $textColor;
  display: flex;
  justify-content: center;
  opacity: 0.94;

  .innerCircle {
    box-sizing: border-box;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 2.7px solid black;
  }

  @include small() {
    width: 60px;
    height: 60px;

    .innerCircle {
      width: 52px;
      height: 52px;
      border: 2px solid black;
    }
  }
}

.SwitchCameraButton {
  path {
    fill: $textColor;
  }

  @include small() {
    svg {
      height: 20px;
      width: auto;
    }
  }
}

.Button.LabelButton {
  @extend .overlay-button;
  border: 0;
  color: $textColor;
  background: none;
  padding: 0;
  display: inline-block;
}

.Button.ShareButton {
  svg {
    height: 32px;
    width: auto;
  }
}

@include small() {
  .ShareButton {
    svg {
      height: 22px;
      width: auto;
    }
  }
}
