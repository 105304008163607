@import '_variables';

@font-face {
  font-family: 'till-normal';
  src: url('/fonts/Till-Normal.woff2') format('woff2'),
    url('/fonts/Till-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vizner';
  src: url('/fonts/vizner-regular-webfont.woff2') format('woff2'),
    url('/fonts/vizner-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: normal;
  margin: 0;
  padding: 0;
  outline: none;
  color: $textColor;
}

body {
  background: var(--ui-color);
  margin: 0;
  padding: 0;
  font-family: 'till-normal';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  .safari & {
    -webkit-text-size-adjust: none;
  }
}

button,
input {
  font-family: 'till-normal';
}

html {
  height: 100%;
  background: black;
}
body {
  height: 100%;
  position: relative;

  .ios & {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}

br.responsive {
  .mobile.landscape & {
    content: ' ';
    &:after {
      content: ' ';
    }
  }
}

br.not-mobile-portrait {
  .mobile.portrait & {
    content: ' ';
    &:after {
      content: ' ';
    }
  }
}

br.on-tablet {
  content: ' ';
  &:after {
    content: ' ';
  }

  @include tablet() {
    content: none;
    &::after {
      content: none;
    }
  }
}

br.off-tablet {
  @include tablet() {
    content: ' ';
    &:after {
      content: ' ';
    }
  }
}

.desktop-only {
  display: none;
  .desktop & {
    display: block;
  }
}

.not-desktop {
  display: block;
  .desktop & {
    display: none;
  }
}
