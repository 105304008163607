@import '../../_typography';
@import '../../_variables';

.Hint {
  @extend .hint-type;
  box-sizing: border-box;
  position: absolute;
  padding: 11px 0 0 0;
  height: 120px;
  width: 100%;
  transform: translate3d(0, -30px, 0);
  pointer-events: none !important;
  transition: transform 400ms ease-out, opacity 400ms ease-out;
  color: $textColor;
  opacity: 0;
  @include scrimGradient(rgba(0, 0, 0, 0.6), 'to bottom');

  &.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.DesktopHint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding-top: 20px;
  @include scrimGradient(rgba(0, 0, 0, 0.6), 'to bottom');
  z-index: $zUi - 1;
  font-size: 28px;
  letter-spacing: -(10em / 1000);
  color: $textColor;
  transform: translate3d(0, -120px, 0);
  pointer-events: none !important;
  transition: transform 400ms ease-out, opacity 400ms ease-out;
  opacity: 0;

  &.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
