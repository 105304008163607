@import '../../_typography';
@import '../../_variables';

.WelcomeScreen {
  width: 100%;
  transform: translateZ(0);
  perspective: 1000;
  opacity: 1;
  transition: opacity 600ms ease-in, transform 800ms ease-in;

  .exit &,
  .enter & {
    opacity: 0;
    transform: translate3d(0, 300px, 0);
  }

  &.new-version {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .Enter {
      justify-content: center;
    }
  }

  .Enter {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding-bottom: 20px;

    &::before {
      content: '';
      height: 150px;
      width: 100%;
      @include scrimGradient(rgba(0, 0, 0, 0.5), 'to top');
      position: absolute;
      top: -150px;
    }

    button {
      @extend .enter-button-type;
      border-bottom: 0;
    }

    .title {
      padding-bottom: 3em;
    }

    @include tablet() {
      padding-bottom: 30px;
    }
  }

  &.new-version {
    .Enter {
      padding-bottom: 0;
    }
  }

  .mobile.landscape & {
    .Enter {
      padding-bottom: 30px;

      h1 {
        margin: 0;
      }

      .title {
        margin-top: -1em;
        padding-bottom: 0;
      }
    }
  }
}
