@import '../../_variables';

.CameraButtons {
  .Button {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: flex;
    align-items: center;
  }

  .RecordButton {
    bottom: 18px;
    left: 50%;
    transform: translate3d(-36px, 0, 0);
  }

  .SwitchCameraButton {
    padding: 15px;
    right: 1px;
    bottom: 10px;
  }

  .CloseButton {
    position: absolute;
    padding: 15px;
    left: 2px;
    bottom: 6px;
  }

  @include small() {
    .CloseButton {
      bottom: 4px;
    }

    .SwitchCameraButton {
      bottom: 7px;
    }

    .RecordButton {
      transform: translate3d(-30px, 0, 0);
    }
  }

  .landscape & {
    .RecordButton {
      bottom: auto;
      left: auto;
      top: 50%;
      transform: translate3d(0, -30px, 0);
      margin-right: env(safe-area-inset-right);
      right: 12px;
    }

    .SwitchCameraButton {
      padding: 12px 5px 5px 12px;
      right: 7px;
      bottom: 9px;
      margin-right: env(safe-area-inset-right);
    }

    .CloseButton {
      padding: 5px 5px 12px 12px;
      bottom: auto;
      left: auto;
      top: 7px;
      right: 7px;
    }
  }

  @include tablet() {
    .landscape & {
      .RecordButton {
        right: 18px;
      }
    }

    .CloseButton {
      left: 0;
      bottom: 0;
    }

    .SwitchCameraButton {
      bottom: 2px;

      svg {
        width: 38px;
        height: auto;
      }
    }
  }
}
