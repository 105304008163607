@import '../../_typography';
@import '../../_variables';

$topbarHeightSmall: 48px;
$topbarHeightMobile: 54px;
$topbarHeightTablet: 67px;
$topbarHeightDesktop: 54px;

.About {
  position: relative;
  z-index: $zAbout;
  background: var(--ui-color);
  min-height: 100%;
  text-align: left;

  .CloseButton {
    position: fixed;
  }

  .AboutTop {
    box-sizing: border-box;
    position: fixed;
    transform: translateZ(0);
    perspective: 1000;
    top: 0;
    width: 100%;
    z-index: $zAbout + 1;
    // align-items: center;
    height: $topbarHeightMobile;
    // border-bottom: 2px solid black;
    display: flex;
    padding-left: 9px;
    padding-top: 17px;

    .mobile.landscape & {
      padding-left: 12px;

      &:after {
        height: 46px;
      }

      &:before {
        top: 46px;
      }
    }

    &:after {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      content: '';
      height: 43px;
      width: 100%;
      background: black;
      pointer-events: none;
    }

    &:before {
      pointer-events: none;
      content: '';
      height: 35px;
      position: absolute;
      top: 43px;
      left: 0;
      width: 100%;
      @include scrimGradient(rgba(0, 0, 0, 1), 'to bottom');
    }

    .Button:not(.SvgButton) {
      @extend .language-type;

      padding: 10px;
      position: relative;
      top: -10px;
      left: -10px;

      // &.selected {
      //   position: relative;

      //   &:after {
      //     content: '';
      //     position: absolute;
      //     right: 2px;
      //     left: 2px;
      //     height: 2px;
      //     background: black;
      //     bottom: -2px;
      //   }
      // }
    }

    .languages {
      button + button {
        margin-left: 1px;
        &:before {
          position: absolute;
          top: 11px;
          left: -2px;
          content: '|';
        }
      }
    }

    .mute {
      margin-left: 1em;

      .Button {
        pointer-events: all;
      }
    }
  }

  .AboutContent {
    @extend .about-type-big;
    padding: ($topbarHeightMobile) 22px 2em;
    height: 100%;
    box-sizing: border-box;

    &.has-bar {
      padding-bottom: 8em;
    }

    p,
    a {
      user-select: text;
    }

    .mobile.landscape & {
      padding-bottom: 4em;
      padding-left: 46px;
      padding-right: 46px;
    }

    .content-big {
      margin-bottom: 2em;

      p {
        margin-top: 0.9em;
      }
    }

    section {
      p + p {
        margin-top: 0.5em;
      }
    }

    section + section,
    .column + .column {
      margin-top: 2em;
    }
  }

  @include small() {
    // .CloseButton {
    //   top: 0;
    //   padding: 13px 8px 20px 20px;

    //   svg {
    //     height: 14px;
    //     width: auto;
    //   }
    // }

    .AboutTop {
      padding-left: 14px;
      height: $topbarHeightSmall;

      .mute {
        margin-left: 0.8em;
      }

      .languages {
        button + button {
          // margin-left: 18px;
        }
      }

      .Button:not(.SvgButton) {
        &.selected {
          &:after {
            bottom: 0;
          }
        }
      }
    }

    .AboutContent {
      padding: ($topbarHeightSmall) 18px 4em;

      &.has-bar {
        padding-bottom: 6em;
      }
    }
  }

  @include tablet() {
    .CloseButton {
      top: 10px;
      right: 11px;
    }

    .AboutTop {
      height: $topbarHeightTablet;
      padding-left: 21px;
      padding-top: 24px;

      &:after {
        height: 60px;
      }

      &:before {
        top: 60px;
      }
    }

    .AboutContent {
      max-width: 560px;
      margin: 0 auto;
      padding: ($topbarHeightTablet) 0 2em 0;

      &.has-bar {
        padding-bottom: (0.5 + $topbarHeightTablet * 2);
      }
    }
  }

  @include desktop() {
    .AboutTop {
      height: $topbarHeightDesktop;
    }

    .AboutContent {
      max-width: 600px;
    }
  }

  .BottomBar {
    position: fixed;
    bottom: 0;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-top: 1em;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
